'use client';

import Link from 'next/link';
import style from './video.module.sass';

import Image from 'next/image';
import { useEffect, useState } from 'react';
import { SliderMain } from '@/Features/Slider/Slider';
import { OpenTeaser } from '@/Hooks/useTiser';

export default function Video() {
	// костыль
	const [pages, setPages] = useState([]);
	// jsApi
	useEffect(() => {
		JsAPI.Dao.getNews(
			{
				block_id: 92474,
				count: 5,
				fields: 65535
			},
			function (payload: any): void {
				setPages(payload);
			}
		);
	}, []);

	return (
		<SliderMain pages={pages} setPages={setPages}>
			{pages.length === 0 ? (
				<section className={style.WrapperOfWrapper}>
					<section className={style.Wrapper}>
						<div className={style.VideoTitle}>
							<svg
								width='16'
								height='11'
								viewBox='0 0 16 11'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M16 9.34095L12.4312 6.5454V9.16252C12.3123 9.87627 11.8959 10.2926 11.1227 10.3521L0.892191 10.2331C0.356875 10.0547 0.0594759 9.63833 0.118955 9.10302L-1.81517e-06 1.19224C0.118957 0.775885 0.41636 0.419008 0.832717 0.300049H11.3011C11.7769 0.300049 12.0743 0.597439 12.1933 1.07328V4.04725L15.9405 1.25171L16 9.34095Z'
									fill='#45AD4E'
								/>
							</svg>
							<h4 className={style.VideoDayFontSize}>Видео дня</h4>
						</div>

						<div
							className={style.videoPreview}
							style={{
								backgroundColor: '#d7d7d7'
							}}
						>
							<div className={style.videoPlay}>
								<Image
									width={17}
									height={20}
									src='/playVideo.svg'
									alt='play Button'
								/>
							</div>
						</div>

						<span className={style.videoName}></span>
					</section>
				</section>
			) : (
				pages.map((el: any) => {
					return (
						<section className={style.WrapperOfWrapper} key={el.id}>
							<a
								href={`${el.url}`}
								style={{ textDecoration: 'none' }}
								target='_blank'
								onClick={e => {
									OpenTeaser(el);
									if (window.ReactNativeWebView) {
										e.preventDefault();
									}
								}}
							>
								<section className={style.Wrapper}>
									<div className={style.VideoTitle}>
										<svg
											width='16'
											height='11'
											viewBox='0 0 16 11'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'
										>
											<path
												d='M16 9.34095L12.4312 6.5454V9.16252C12.3123 9.87627 11.8959 10.2926 11.1227 10.3521L0.892191 10.2331C0.356875 10.0547 0.0594759 9.63833 0.118955 9.10302L-1.81517e-06 1.19224C0.118957 0.775885 0.41636 0.419008 0.832717 0.300049H11.3011C11.7769 0.300049 12.0743 0.597439 12.1933 1.07328V4.04725L15.9405 1.25171L16 9.34095Z'
												fill='#45AD4E'
											/>
										</svg>
										<h4 className={style.VideoDayFontSize}>Видео дня</h4>
									</div>

									<div
										className={style.videoPreview}
										style={{
											backgroundImage: `url(https:${el.image})`,
											backgroundSize: 'cover'
										}}
									>
										<div className={style.videoPlay}>
											<Image
												width={17}
												height={20}
												src='/playVideo.svg'
												alt='play Button'
											/>
										</div>
									</div>

									<span className={style.videoName}>{el.title}</span>
								</section>
							</a>
						</section>
					);
				})
			)}
		</SliderMain>
	);
}
