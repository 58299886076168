export const GetPollsFetch = ({ PollId }: any) => {
	return fetch(`https://polls.smi2.ru/body/1/poll/${PollId}`, {
		method: 'GET',
		mode: 'cors',

		headers: new Headers({ 'content-type': 'application/json' }),
	});
};

export const GetPollsVoteFetch = ({ PollId, options }: any) => {
	return fetch(`https://polls.smi2.ru/body/1/poll/${PollId}/vote`, {
		method: 'POST',
		mode: 'cors',
		body: `optionId=${options}`,

		headers: new Headers({
			'content-type': 'application/x-www-form-urlencoded',
		}),
	});
};
