'use client';

import { useAppSelector } from '@/Hooks/redux';
import style from './quiz.module.sass';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getPolls } from '@/Redux/saga/getPolls';
import { getVote } from '@/Redux/saga/getPollsVote';
import Link from 'next/link';
import { PollsState } from '@/Redux/Slices/PollsSlice/PollsState';
import { SliderMain } from '@/Features/Slider/Slider';
import { OpenTeaser } from '@/Hooks/useTiser';

interface AdfoxAsyncParams {
	ownerId?: number;
	params?: Record<string, any>;
	onRender?: (data: any) => void;
	onError?: (error: Error) => void;
	containerId?: string;
}
declare global {
	interface Window {
		adfoxAsyncParams: AdfoxAsyncParams[];
	}
}

export default function Quiz() {
	const [pages, setPages] = useState<any>(0);
	const [TouchEvent, setTouchEvent] = useState(0);
	const [Arrows, setArrows] = useState(0);
	const handleTouchStart = (e: any) => {
		setTouchEvent(e.touches[0].clientX);
	};
	const handleTouchEnd = (e: any) => {
		const endX = e.changedTouches[0].clientX;
		if (TouchEvent - endX > 50) {
			setArrows(3);
		} else if (endX - TouchEvent > 50) {
			setArrows(2);
		}
	};

	const { Answer, isOn }: any = useAppSelector(state => state.PollsState);
	const { SetAnswer } = PollsState.actions;

	const dispatch = useDispatch();
	const { data, Loading }: any = useAppSelector(state => state.getPollsAll);

	// Хук для adfoxAsyncParams
	useEffect(() => {
		window.adfoxAsyncParams = window.adfoxAsyncParams || [];
		window.adfoxAsyncParams.push({
			ownerId: 211731,
			containerId: 'adfox_159067343332936426',
			params: {
				p1: 'cktpr',
				p2: 'fpaj',
				puid1: '',
				puid2: '',
				puid3: '',
				puid4: ''
			}
		});
	}, []);

	// Хук для JsAPI.Dao.getNews
	useEffect(() => {
		JsAPI.Dao.getNews(
			{
				block_id: 92475,
				count: 5,
				fields: 65535
			},
			function (payload) {
				if (payload[0]?.id) {
					payload.forEach((el: any) => {
						dispatch(
							getPolls({
								PollId: Number(el.text)
							})
						);
					});
				}
			}
		);
	}, [dispatch]);

	// Хук для установки страниц
	useEffect(() => {
		if (!Loading && data.length > 0) {
			setPages(data);
		}
	}, [Loading, data]);

	if (Loading) {
		return (
			<section
				className={style.WrapperOfWrapper}
				onTouchStart={handleTouchStart}
				onTouchEnd={handleTouchEnd}
			>
				<section className={style.Wrapper}>
					<div className={style.quizTitle}>
						<svg
							width='18'
							height='15'
							viewBox='0 0 18 15'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								d='M4.77666e-06 4.85105L18 0.938007V12.6771L4.77666e-06 8.76409V4.85105Z'
								fill='#45AD4E'
							/>
							<path
								d='M3.91333 8.76409V12.6771C3.91333 12.6771 3.91333 13.4597 4.69594 13.4597H10.1742C10.1742 13.4597 10.9568 13.4597 10.9568 12.6771V8.76409'
								stroke='#45AD4E'
								strokeWidth='2'
								strokeMiterlimit='10'
							/>
						</svg>
						<h4 className={style.QuizFont}>Опрос</h4>
					</div>
					<div className={style.quiz}>
						<div className={style.quizWraperLoading}></div>

						<div className={style.ContBorderLoading}>
							<div className={style.OneLint}></div>
							<div className={style.OneBigLint}></div>
							<div className={style.OneBigLint}></div>
						</div>
					</div>
				</section>
			</section>
		);
	}

	const Graphlogic = (index: any, indexAll: any) => {
		try {
			const totalVotes = data[indexAll]?.options?.reduce(
				(sum: any, item: any) => sum + item.votes,
				0
			);

			return Math.round(
				data[indexAll]?.options[index]?.votes / (totalVotes / 100)
			);
		} catch (e) {
			console.error(e);
		}
	};

	return (
		<SliderMain pages={pages} setPages={setPages} Arrows={5}>
			{pages?.length > 0 ? (
				pages.map((el: any, i: any) => {
					if (!el) return null; // Проверка на undefined
					return (
						<section
							className={style.WrapperOfWrapper}
							key={el?.id || i}
							onTouchStart={handleTouchStart}
							onTouchEnd={handleTouchEnd}
						>
							<section className={style.Wrapper}>
								<div className={style.quizTitle}>
									<svg
										width='18'
										height='15'
										viewBox='0 0 18 15'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<path
											d='M4.77666e-06 4.85105L18 0.938007V12.6771L4.77666e-06 8.76409V4.85105Z'
											fill='#45AD4E'
										/>
										<path
											d='M3.91333 8.76409V12.6771C3.91333 12.6771 3.91333 13.4597 4.69594 13.4597H10.1742C10.1742 13.4597 10.9568 13.4597 10.9568 12.6771V8.76409'
											stroke='#45AD4E'
											strokeWidth='2'
											strokeMiterlimit='10'
										/>
									</svg>
									<h4 className={style.QuizFont}>Опрос</h4>
								</div>
								<div className={style.quiz}>
									<div
										className={style.quizWraper}
										style={{
											backgroundImage: `url(${el?.image || ''})`
										}}
									></div>

									<div className={style.ContBorder}>
										<h4 className={style.quizQuestion}>{el?.title || ''}</h4>
										{isOn.includes(el?.id) ? (
											<form className={style.quizAnswerVote}>
												<div className={style.AllCont}>
													{el?.options?.map((els: any, index: any) => {
														if (!els) return null; // Проверка на undefined
														return (
															<div className={style.YesAnswer} key={els?.id}>
																<h4 className={style.AnswerYes}>
																	{els?.title}
																</h4>
																<div className={style.contGraph}>
																	<div className={style.graphYes}>
																		<div
																			className={style.graph}
																			style={
																				Answer[i]?.ans === els?.id &&
																				Answer[i]?.numQuiz === el?.id
																					? {
																							width: `${Graphlogic(index, i)}%`,
																							backgroundColor: '#45ad4e'
																					  }
																					: {
																							width: `${Graphlogic(index, i)}%`,
																							backgroundColor: '#969696'
																					  }
																			}
																		></div>
																	</div>
																	<h4 className={style.ProcentYes}>
																		{`${Graphlogic(index, i)}%`}
																	</h4>
																</div>
															</div>
														);
													})}
												</div>
												<div className={style.Comment}>
													<p>{el?.options[0]?.text || ''}</p>
													<a
														href={el?.options[1]?.url || '#'}
														className={style.ReadMore}
														target='_blank'
														onClick={e => {
															OpenTeaser(el);
															if (window.ReactNativeWebView) {
																e.preventDefault();
															}
														}}
													>
														Читать подробнее
													</a>
												</div>
											</form>
										) : (
											<form
												className={style.quizAnswer}
												onSubmit={e => e.preventDefault()}
											>
												{el?.options?.map((elOpt: any, ind: any) => {
													if (!elOpt) return null; // Проверка на undefined
													return (
														<button
															key={elOpt?.id}
															className={style.Btn}
															onClick={() => {
																dispatch(
																	SetAnswer({
																		answer: elOpt?.id,
																		active: el?.id
																	})
																);
																dispatch(
																	getVote({
																		PollId: el?.id,
																		options: elOpt?.id
																	})
																);
															}}
														>
															<h4>{elOpt?.title}</h4>
														</button>
													);
												})}
											</form>
										)}
									</div>
								</div>
							</section>
						</section>
					);
				})
			) : (
				<></>
			)}
		</SliderMain>
	);
}
