export const OpenTeaser = (els: any) => {
	if (window.ReactNativeWebView) {
		// url
		// const message = {
		// 	type: 'openTeaser',
		// 	url: els
		// };

		window.ReactNativeWebView.postMessage(JSON.stringify(['openTeaser', els]));
	} else {
	}
};
